import React from "react";
import "./Banner.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Banner = () => {

    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = () => {
     navigate("/contact-us");
   };

    return(
        <div className="banner-phishing-container">
            <div className="banner-phishing-area">
                <div className="banner-phishing-heading">
                Empower Your Team With Cybersecurity Awareness.
                {/* <br></br>
                GET IN TOUCH WITH US */}
                </div>
                <hr className="phishing-hr"/>
                <div className="banner-phishing-content">Arm your workforce against phishing threats with our engaging and realistic Phishing Simulations. We create tailored scenarios that educate and empower your employees, transforming them into the first line of defense. Stay one step ahead of cybercriminals and cultivate a culture of vigilance! </div>
                <Button variant="contained" className="banner-phishing-Btn" onClick={handleRedirect}>Start Building Awareness Now</Button>
            </div>
        </div>
    )

}
export default Banner;