import React from "react";
import "./Banner.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Banner = () => {

    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = () => {
     navigate("/contact-us");
   };

    return(
        <div className="banner-soc-container">
            <div className="banner-soc-area">
                <div className="banner-soc-heading">
                Continuous Protection For Your Digital Landscape.
                </div>
                <hr className="banner-soc-hr"/>
                <div className="banner-soc-content">Cyber threats don’t rest, and neither does our SOC. With round-the-clock monitoring and rapid incident response, we safeguard your business from evolving cyber threats. Our team of experts is always on watch, ensuring that your digital environment remains secure 24/7- invest in security that never sleeps! </div>
                <Button variant="contained" className="banner-soc-Btn" onClick={handleRedirect}>Connect With Us Today</Button>
            </div>
        </div>
    )

}
export default Banner;