import React from "react";
import redTeamBriefVisual from "../../assets/redTeamBriefVisual.png"
import './Brief.css';

const Brief = () => {
  return (
    <div className="brief-rt-container">
        <div className="brief-rt-content">
        <div className="brief-rt-heading">
        Choose Cyberion And  
      <br></br>Ensure Your Defenses Are
      <br></br>Not Just Strong But Ununbreakable.
      </div> 
      <hr className="brief-rt-hr"/>
      <div className="brief-rt-description">
      Our Red Teaming exercises go beyond conventional testing. We emulate sophisticated threat actors to expose critical vulnerabilities and provide actionable insights. This advanced approach means we don’t just test your defenses; we refine them, turning your organization into a fortress ready for real-world attacks.
      </div>
        </div>
        <img src={redTeamBriefVisual} alt="red team explained" className="brief-rt-image"></img>
    </div>
  )
}

export default Brief;