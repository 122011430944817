import React from "react";
import contactUsVisual from "../../assets/contactUsVisual.png"
import './Landing.css';

const Landing = () => {
  return (
    <div className="landing-contact-container">
        <div className="landing-contact-content">
        <div className="landing-contact-heading">
        Discover What A Robust Cybersecurity AI Platform Like Cyberion Can Do For Your Organization. Share Your Details, And We’ll Connect You With A Cyberion Expert.
      </div> 
      <hr className="landing-contact-hr"/>
      <div className="landing-contact-description1">
      ✔ Accelerate Threat Response: Detect and neutralize threats at unmatched speed, scale, and precision—far beyond human capabilities.
      </div>
      <div className="landing-contact-description">
      ✔ Intelligent Security Integration: Integrate advanced AI-driven insights into your cybersecurity framework, mitigating tunnel vision and consolidation risks across your security layers.
      </div>
      <div className="landing-contact-description">
      ✔ Comprehensive Protection: Take control across all domains—Endpoint, Cloud, and Data. Enhance your security investments or replace legacy systems while cutting costs.
      </div>
      <div className="landing-contact-description2">Reach out today to experience Cyberion’s next-generation defense systems tailored to your organization’s unique needs.
        </div>
        </div>
        <div className="landing-contact-right">
        <img src={contactUsVisual} alt="contact Us explained" className="landing-contact-image"></img>
        <div className="landing-contact-description3">Take Command of Your Digital Security Today By Connecting With Us At
        </div>
        <div className="email">
        <a href="mailto:business@cyberionlabs.com" className="email">business@cyberionlabs.com</a>
        </div>
        </div>
    </div>
  )
}

export default Landing;