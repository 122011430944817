import React from "react"
import Landing from "../components/RedTeam/Landing";
import Navbar from "../components/Navbar/Navbar";
import Features from "../components/RedTeam/Features";
import Brief from "../components/RedTeam/Brief";
import Banner from "../components/RedTeam/Banner";
import Stats from "../components/RedTeam/Stats";
import Footer from "../components/Footer/Footer";

const RedTeam = () => {

    return(
        <>
        <Navbar />
        <Landing />
        <Stats />
        <Features />
        <Brief />
        <Banner />
        <Footer />
        </>
    )
}
export default RedTeam;