import React from "react";
import './Landing.css';

const Landing = () => {

  return (
    <div className="about-landing-container">
      <div className="about-landing-content">
        <div className="about-landing-heading">ABOUT US: 
        </div>
        <div className="main-heading">THE CYBERION JOURNEY </div>
        <div className="about-landing-description">In the vast, ever-expanding digital universe, we noticed something unsettling. Threats—silent, invisible, and persistent—roamed the virtual world, preying on vulnerabilities. It wasn't just systems at risk; it was trust, security, and innovation. The more we explored, the clearer it became: businesses were locked in an endless chase, battling enemies they could neither see nor fully understand. 
        </div>
      </div>
    </div>
  )
}

export default Landing;