import React from "react"
import Navbar from "../components/Navbar/Navbar";
import Landing from "../components/InfraMonitoring/Landing";
import Stats from "../components/InfraMonitoring/Stats";
import Features from "../components/InfraMonitoring/Features";
import Brief from "../components/InfraMonitoring/Brief";
import Banner from "../components/InfraMonitoring/Banner";
import Footer from "../components/Footer/Footer";


const InfraMonitoring = () => {

    return(
        <>
        <Navbar />
        <Landing />
        <Stats />
        <Features />
        <Brief />
        <Banner />
        <Footer />
        </>
    )
}
export default InfraMonitoring;