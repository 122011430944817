import React from "react";
import infraVisual from "../../assets/infraVisual.png";
import infraVisualMob from "../../assets/infraVisualMob.png";
import './Features.css';

const Features = () => {
  return (
    <div className="features-infra-container">
      <div className="features-infra-heading">
      Empowering Your Workforce Against Cyber Threats
      </div>
      <div className="features-infra-content">
        <img src={infraVisual} alt="infra monitoring explained" className="features-infra-image"></img>
        <img src={infraVisualMob} alt="infra monitoring explained" className="features-infra-image-mob"></img>
        
      </div>
    </div>
  )
}

export default Features;