import React from "react";
import infraBriefVisual from "../../assets/infraBriefVisual.png"
import './Brief.css';

const Brief = () => {
  return (
    <div className="brief-infra-container">
        <div className="brief-infra-content">
        <div className="brief-infra-heading">
        Trust Cyberion To Keep Your  
      <br></br>Operations Running Smoothly,
      <br></br>24/7, Without Compromise.
      </div> 
      <hr className="brief-infra-hr"/>
      <div className="brief-infra-description">
      Cyberion offers more than just monitoring—we provide a proactive shield around your infrastructure. With real-time threat detection, predictive analysis, and immediate action protocols, we don’t just identify risks—we neutralize them before they cause harm.
      </div>
        </div>
        <img src={infraBriefVisual} alt="infra explained" className="brief-infra-image"></img>
    </div>
  )
}

export default Brief;