import React from "react";
import socVisual from "../../assets/socVisual.png";
import socVisualMob from "../../assets/socVisualMob.png";
import './Features.css';

const Features = () => {
  return (
    <div className="soc-features-container">
      <div className="soc-features-heading">
      24/7 Threat Surveillance for Total Peace of Mind
      </div>
      <div className="soc-features-content">
        <img src={socVisual} alt="soc explained" className="soc-features-image"></img>
        <img src={socVisualMob} alt="soc explained" className="soc-features-image-mob"></img>
      </div>
    </div>
  )
}

export default Features;