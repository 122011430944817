import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ServicesOverview.css";

const ServicesOverview = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

   // Function to handle navigation
   const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="services-container">
      <h2 className="services-title">Our Services</h2>
      <div className="services-grid">
        <div
          className={`service-card ${hoveredIndex === 0 ? "flipped" : ""}`}
          onMouseEnter={() => setHoveredIndex(0)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => handleCardClick("/red-team-service")} // Redirect to redTeam page
        >
          <div className="service-card-inner">
            <div className="service-card-front red-team-bg">
              <div>Red Teaming</div>
            </div>
            <div className="service-card-back">
              <p>Outsmart the Threats Before They Strike</p>
            </div>
          </div>
        </div>

        <div
          className={`service-card ${hoveredIndex === 1 ? "flipped" : ""}`}
          onMouseEnter={() => setHoveredIndex(1)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => handleCardClick("/phishing-service")} // Redirect to phishing page
        >
          <div className="service-card-inner">
            <div className="service-card-front phishing-bg">
              <div>Cyber Security Awareness</div>
            </div>
            <div className="service-card-back">
              <p>Train Your Team to Spot the Hook, Not Bite It.
              </p>
            </div>
          </div>
        </div>

        <div
          className={`service-card ${hoveredIndex === 2 ? "flipped" : ""}`}
          onMouseEnter={() => setHoveredIndex(2)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => handleCardClick("/vapt-service")} // Redirect to VAPT page
        >
          <div className="service-card-inner">
            <div className="service-card-front vapt-bg">
              <div>Vulnerability Assessment and Penetration Testing</div>
            </div>
            <div className="service-card-back">
              <p>  Uncover Weaknesses Before They Become Breaches.</p>
            </div>
          </div>
        </div>

        <div
          className={`service-card ${hoveredIndex === 3 ? "flipped" : ""}`}
          onMouseEnter={() => setHoveredIndex(3)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => handleCardClick("/infra-monitoring-service")} // Redirect to Infrastructure Monitoring page
        >
          <div className="service-card-inner">
            <div className="service-card-front infra-bg">
              <div>Infrastructure Monitoring</div>
            </div>
            <div className="service-card-back">
              <p>24/7 Watch. Instant Defense. Total Peace of Mind.</p>
            </div>
          </div>
        </div>

        <div
          className={`service-card ${hoveredIndex === 4 ? "flipped" : ""}`}
          onMouseEnter={() => setHoveredIndex(4)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => handleCardClick("/soc-service")} // Redirect to SOC page
        >
          <div className="service-card-inner">
            <div className="service-card-front soc-bg">
              <div>Security Operation Centre</div>
            </div>
            <div className="service-card-back">
              <p>Your Cyber Shield in a World of Constant Threats.
              </p>
            </div>
          </div>
        </div>

        {/* <div
          className={`service-card ${hoveredIndex === 5 ? "flipped" : ""}`}
          onMouseEnter={() => setHoveredIndex(5)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => handleCardClick("/securityTesting")} // Redirect to Security Testing page
        >
          <div className="service-card-inner">
            <div className="service-card-front security-bg">
              <div>Security Testing</div>
            </div>
            <div className="service-card-back">
              <p>Ensure your applications and systems are secure.</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ServicesOverview;
