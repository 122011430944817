import React from "react";
import phishingBriefVisual from "../../assets/phishingBriefVisual.png"
import './Brief.css';

const Brief = () => {
  return (
    <div className="brief-phishing-container">
        <div className="brief-phishing-content">
        <div className="brief-phishing-heading">
        Cyberion Transforms Your Team 
      <br></br>Into A Vigilant Force  
      <br></br>Against Social Engineering.
      </div> 
      <hr className="brief-phishing-hr"/>
      <div className="brief-phishing-description">
      Cyberion: The Pinnacle of Cybersecurity Awareness. We don’t just simulate attacks; we educate. At Cyberion, our cybersecurity awareness programs are designed to not only expose weak points but also elevate your team's awareness. Our tailored training adapts to emerging threats, making your workforce an impenetrable line of defense against cybersecurity risks.
      </div>
        </div>
        <img src={phishingBriefVisual} alt="phishing explained" className="brief-phishing-image"></img>
    </div>
  )
}

export default Brief;