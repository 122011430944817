import React from "react";
import './Home.css';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomeBase = () => {

    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = () => {
     navigate("/contact-us");
   };


  return (
    <div className="home-container">

      <div className="content">
        <h1 className="heading">Zero Tolerance for Threats, Infinite Defense for You
        </h1>
        <p className="description">At Cyberion, we offer advanced cybersecurity frameworks that identify vulnerabilities and prepare you for real-time breaches. By simulating threats and providing expert insights, we empower you to anticipate risks and navigate the digital landscape with confidence.
        </p>
        <Button variant="contained" className="ctaBtn" onClick={handleRedirect}>Connect With Us Today</Button>
      </div>
    </div>
  )
}

export default HomeBase;