import React from "react";
import './Landing.css';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Landing = () => {

    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = () => {
     navigate("/contact-us");
   };

  return (
    <div className="soc-landing-container">
      <div className="soc-landing-content">
        <h1 className="soc-landing-heading">Security Operation Centre
        </h1>
        <p className="soc-landing-description">Proactive monitoring, instant action—your security, always under watch. 
        </p>
        <Button variant="contained" className="soc-landing-ctaBtn" onClick={handleRedirect}>Connect With Us Today</Button>
      </div>
    </div>
  )
}

export default Landing;