import React from "react";
import './Overview.css';
import overviewVisual from "../../assets/overviewVisual.png"
import overviewVisualMob from "../../assets/overviewVisualMob.png";
const Overview = () => {
  return (
    <div className="overview-container">
      <div className="overview-heading">
      Empowering Your 
      
        <br />
        Workforce  Against <br></br><span className="text-highlight">Cyber Threats</span>
      </div>
      <div className="overview-content">
        <img src={overviewVisual} alt="overview explained" className="overview-image"></img>
        <img src={overviewVisualMob} alt="overview explained" className="overview-image-mob"></img>

      </div>
    </div>
  );
}

export default Overview;
