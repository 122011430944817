import React from "react";
import infraStatsVisual from "../../assets/infraStatsVisual.png";
import './Stats.css';

const Stats = () => {
  return (
    <div className="stats-infra-container">
        <div className="stats-infra-left">
            <div className="stats-infra-heading">
            The Impact Of 
                <br></br>Infrastructure Monitoring And
                <br></br><div className="stats-infra-standout"> Rapid Response</div>
            </div>
            <div className="stats-infra-description">
            As technology evolves, maintaining the health of your IT infrastructure becomes increasingly vital. Infrastructure monitoring provides continuous oversight of your systems, both traditional and cloud-based, ensuring optimal performance and stability. By proactively identifying issues and inefficiencies, it allows for timely intervention and adjustments, thus reducing downtime and preventing potential service disruptions. This strategic oversight is essential for ensuring that your technology infrastructure supports your business effectively.
            </div>
        </div>
        <div className="stats-infra-content">
        <img src={infraStatsVisual} alt="infra explained" className="stats-infra-image"></img>
        </div>
    </div>
  )
}

export default Stats;