import React from "react"
import Landing from "../components/VAPT/Landing";
import Navbar from "../components/Navbar/Navbar";
import Stats from "../components/VAPT/Stats";
import Features from "../components/VAPT/Features";
import Brief from "../components/VAPT/Brief";
import Banner from "../components/VAPT/Banner";
import Footer from "../components/Footer/Footer";

const Vapt = () => {

    return(
        <>
        <Navbar />
        <Landing />
        <Stats />
        <Features />
        <Brief />
        <Banner />
         <Footer />
        </>
    )
}
export default Vapt;