import React from "react";
import phishingStatsVisual from "../../assets/phishingStatsVisual.png";
import './Stats.css';

const Stats = () => {
  return (
    <div className="stats-phishing-container">
        <div className="stats-phishing-left">
            <div className="stats-phishing-heading">
            Why Cybersecurity Awareness  
                <br></br>Is Vital For The 
                <br></br><div className="stats-phishing-standout">Modern Digital Space?</div>
            </div>
            <div className="stats-phishing-description">
            In today’s landscape of sophisticated cyber threats, cybersecurity awareness is an indispensable tool for fortifying your defenses. By mimicking deceptive tactics such as phishing, vishing, and smishing, simulations test your team's ability to recognize and respond to social engineering attacks. This proactive approach not only sharpens awareness but also helps in pinpointing areas where further training is needed, ultimately reducing the risk of successful phishing attempts and protecting sensitive information.
            </div>
        </div>
        <div className="stats-phishing-content">
        <img src={phishingStatsVisual} alt="phishing explained" className="stats-phishing-image"></img>
        </div>
    </div>
  )
}

export default Stats;