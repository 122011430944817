import React from "react";
import aboutVisual1 from "../../assets/aboutVisual1.png"
import './OurMission.css';

const OurMission = () => {
  return (
    <div className="about-mission-container">
        <div className="about-mission-content">
            <div className="about-mission-heading">
                That’s When Cyberion Was Born.
            </div>  
            <img src={aboutVisual1} alt="mission explained" className="about-mission-image"></img> 
        </div>
    <div className="description-wrapper">
      <div className="about-mission-description">
        We saw the gap and took up the challenge. <b>Our mission was clear: </b>To not only fight the battles of today but to create solutions that fortify the digital landscapes of tomorrow. We knew the answer lies in precision, intelligence, and adaptability.
      </div>
      <div className="about-mission-description">
        So, we assembled a team of forward-thinking cybersecurity experts with a singular goal—empower organizations to be proactive rather than reactive in the face of cyber threats.
      </div>
      </div>
    </div>
  )
}

export default OurMission;