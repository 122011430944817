import React from "react";
import { Link } from "react-router-dom"; // Make sure you have react-router-dom installed
import './Footer.css'; // Ensure the CSS file is in the same directory as this component
import ionLogo from "../../assets/ionLogo.png";

const Footer = () => {
  return (
    <footer className="footer-container">
        <div className="company-content">
            <img src={ionLogo} alt="company logo" className="company-logo"></img>
            <div className="slogan">Where Quality Meets Innovation</div>
            <div className="footer-descripton">We guide you through every aspect, to safeguard your business against any potential vulnerabilities!
            </div>
        </div>
      <div className="footer-links">
        <Link to="/about-us">About Us</Link>
        <Link to="/contact-us">Contact Us</Link>
        <Link to="/red-team-service">Red Teaming</Link>
        <Link to="/phishing-service">Cyber Security Awareness</Link>
        <Link to="/vapt-service">VAPT</Link>
        <Link to="/infra-monitoring-service">Infrastructure Monitoring</Link>
        <Link to="/soc-service">Security Operation Centre</Link>
      </div>
      <div className="copyright-footer">All rights reserved © 2024 Cyberion</div>
    </footer>
  );
};

export default Footer;
