import React from "react"
import Homebase from "../components/Home/Home";
import Navbar from "../components/Navbar/Navbar";
import Overview from "../components/Home/Overview";
import ServicesOverview from "../components/Home/ServicesOverview";
import WhyUs from "../components/Home/WhyUs";
import Banner from "../components/Home/Banner";
import Footer from "../components/Footer/Footer";
const Home = () => {

    return(
        <>
        <Navbar />
        <Homebase />
        <Overview />
        <ServicesOverview />
        <WhyUs />
        <Banner />
        <Footer />
        </>
    )
}
export default Home;