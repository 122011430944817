import React from "react";
import "./Banner.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Banner = () => {

    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = () => {
     navigate("/contact-us");
   };

    return(
        <div className="banner-rt-container">
            <div className="banner-rt-area">
                <div className="banner-rt-heading">
                Challenge The Limits Of Your Security.
                {/* <br></br>
                GET IN TOUCH WITH US */}
                </div>
                <hr className="rt-banner-hr"/>
                <div className="banner-rt-content">Test your defenses like never before with our Red Teaming Exercise. We simulate sophisticated, real-world attacks to expose vulnerabilities in your system, ensuring your security is as strong as possible. Prepare for the unexpected with proactive strategies that keep you ahead of the adversary! </div>
                <Button variant="contained" className="banner-rt-Btn" onClick={handleRedirect}>Partner With Us Today</Button>
            </div>
        </div>
    )

}
export default Banner;