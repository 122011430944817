import React from "react";
import vaptStatsVisual from "../../assets/vaptStatsVisual.png";
import './Stats.css';

const Stats = () => {
  return (
    <div className="stats-vapt-container">
        <div className="stats-vapt-left">
            <div className="stats-vapt-heading">
            Why Is VAPT Crucial 
                <br></br>Is Essential For 
                <br></br><div className="stats-vapt-standout">Your Cybersecurity Strategy?</div>
            </div>
            <div className="stats-vapt-description">
            In an age where digital landscapes are constantly shifting, Vulnerability Assessment and Penetration Testing (VAPT) stands out as a fundamental practice for identifying and addressing potential security gaps. VAPT offers a meticulous examination of your network, applications, and servers to uncover weaknesses before malicious actors can exploit them. This methodical approach ensures that technical defenses are robust, helping prevent data breaches and maintaining the integrity of your cybersecurity posture.
            </div>
        </div>
        <div className="stats-vapt-content">
        <img src={vaptStatsVisual} alt="vapt explained" className="stats-vapt-image"></img>
        </div>
    </div>
  )
}

export default Stats;