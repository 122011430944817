import React from "react";
import vaptVisual from "../../assets/vaptVisual.png";
import vaptVisualMob from "../../assets/vaptVisualMob.png";
import './Features.css';

const Features = () => {
  return (
    <div className="vapt-features-container">
        <img src={vaptVisual} alt="vaptexplained" className="vapt-features-image"></img>
        <img src={vaptVisualMob} alt="vaptexplained" className="vapt-features-image-mob"></img>
      {/* </div> */}
    </div>
  )
}

export default Features;