import React from "react";
import vaptBriefVisual from "../../assets/vaptBriefVisual.png"
import './Brief.css';

const Brief = () => {
  return (
    <div className="brief-vapt-container">
        <div className="brief-vapt-content">
        <div className="brief-vapt-heading">
        At Cyberion, 
      <br></br>We Don’t Just Find Weaknesses;
      <br></br> We Help You Build 
      <br></br>Invincible Defenses.
      </div> 
      <hr className="brief-vapt-hr"/>
      <div className="brief-vapt-description">
      Cyberion’s VAPT service delivers more than vulnerability assessments—it provides a roadmap to security excellence. Our meticulous combination of vulnerability assessments and penetration testing ensures that your digital assets are not just tested but fortified against even the most persistent attackers.
      </div>
        </div>
        <img src={vaptBriefVisual} alt="vapt explained" className="brief-vapt-image"></img>
    </div>
  )
}

export default Brief;