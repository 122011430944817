import React from "react";
import rtStatsVisual from "../../assets/rtStatsVisual.png";
import './Stats.css';

const Stats = () => {
  return (
    <div className="stats-rt-container">
        <div className="stats-rt-left">
            <div className="stats-rt-heading">
                Why Red Teaming 
                <br></br>Is Essential For 
                <br></br><div className="stats-rt-standout">Modern Cybersecurity?</div>
            </div>
            <div className="stats-rt-description">
            In an era where cyber threats evolve rapidly, ensuring the resilience of your cybersecurity measures is more crucial than ever. Red teaming emerges as a pivotal strategy, offering organizations a proactive approach to uncover vulnerabilities and reinforce their defenses against sophisticated cyber-attacks. By simulating realistic attack scenarios, red teaming provides invaluable insights that help prevent costly security breaches and safeguard critical data.
            </div>
        </div>
        <div className="stats-rt-content">
        <img src={rtStatsVisual} alt="red team explained" className="stats-rt-image"></img>
        </div>
    </div>
  )
}

export default Stats;