import React from "react";
import "./Banner.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Banner = () => {

    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = () => {
     navigate("/contact-us");
   };

    return(
        <div className="banner-container">
            <div className="banner-area">
                <div className="banner-heading">Embark On A Journey 
                <br/>   To Unparalleled 
                <br /> 
                Cybersecurity With Us.
                </div>
                <hr />
                <div className="banner-content"> We empower you to safeguard your digital assets with innovative security solutions. From advanced risk identification to peculiarly designed defense strategies, we help you stay ahead of cyber threats. Secure your future with us and connect today!
                </div>
                <Button variant="contained" className="home-banner-Btn" onClick={handleRedirect}> Connect With Us Today</Button>
            </div>
        </div>
    )

}
export default Banner;