import React from "react";
import "./WhyUs.css";
import WhyUsVisual from "../../assets/WhyUsVisual.png";
import whyUsVisualMob from "../../assets/whyUsVisualMob.png";

const WhyUs = () => {

    return(
    <div className="whyUs-container">
      <div className="whyUs-heading">
      The Cyberion Edge: 
      <br></br>Why We’re Your Best Defense ?
      </div>
      <div className="whyUs-content">
        <img src={WhyUsVisual} alt="why choose us" className="whyUs-image"></img>
        <img src={whyUsVisualMob} alt="why choose us" className="whyUs-image-mob"></img>
        <div className="pointer-container">
            <ul>
                <li>
               <b className="pointer-heading">Human-Centered Security Approach</b> <br></br>
               Cyberion combines human expertise with technology, training your team to actively detect and counter threats, enhancing your security stance.
          </li> <br/> <br />

          <li>  <b className="pointer-heading"> Transparent and Customized Security Insights</b> <br></br>

          We provide clear, tailored security insights that are easy to understand and actionable, improving communication between your security team and stakeholders.
 </li> <br /> <br />

         <li>   <b className="pointer-heading">Collaborative Security Partnerships</b> <br></br>
         We treat clients as partners, working closely to develop evolving, bespoke security solutions tailored to your needs.
                </li> <br/> <br />
                <li>
               <b className="pointer-heading">Swift Response, Zero Downtime</b>  <br></br>
               Our rapid response capabilities ensure minimal operational disruptions, with automated systems and experts ready to maintain continuity. </li> <br/> <br />
            </ul>
        </div>
      </div>
    </div>
    )

}
export default WhyUs;