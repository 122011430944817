import React from "react";
import socStatsVisual from "../../assets/socStatsVisual.png";
import './Stats.css';

const Stats = () => {
  return (
    <div className="stats-soc-container">
        <div className="stats-soc-left">
            <div className="stats-soc-heading">
            The Importance Of A 
                <br></br><div className="stats-soc-standout">Security Operations Center (SOC)</div>
            </div>
            <div className="stats-soc-description">
            In an era of persistent cyber threats, having a dedicated Security Operations Center (SOC) is crucial for real-time threat management. A SOC operates as a command center for cybersecurity, vigilantly monitoring, analyzing, and responding to potential threats around the clock. By providing a layer of constant vigilance and advanced threat detection, a SOC ensures that malicious activities are addressed swiftly, minimizing the risk of breaches and enhancing overall security resilience.
            </div>
        </div>
        <div className="stats-soc-content">
        <img src={socStatsVisual} alt="soc explained" className="stats-soc-image"></img>
        </div>
    </div>
  )
}

export default Stats;