import React from "react"
import Navbar from "../components/Navbar/Navbar";
import Landing from "../components/ContactUs/Landing";
import Footer from "../components/Footer/Footer";

const ContactUs = () => {

    return(
        <>
        <Navbar />
        <Landing />
        <Footer />
        </>
    )
}
export default ContactUs;