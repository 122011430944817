import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Navbar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer/Footer";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import './App.css';
import RedTeam from "./pages/redTeam";
import Vapt from "./pages/vapt";
import Phishing from "./pages/phishing";
import InfraMonitoring from "./pages/infraMonitoring";
import SOC from "./pages/soc";
import ContactUs from "./pages/contactUs";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/red-team-service" element={<RedTeam />} />
        <Route path="phishing-service" element={<Phishing />} />
        <Route path="/vapt-service" element={<Vapt />} />
        <Route path="/infra-monitoring-service" element={<InfraMonitoring />} />
        <Route path="/soc-service" element={<SOC />} />
        <Route path="contact-us" element={<ContactUs />} />

      </Routes>
    </Router>
  );
}

export default App;
