import * as React from 'react';
import { AppBar, Box, Toolbar, Typography, Button, MenuItem, Menu, Container, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ionLogo from "../../assets/ionLogo.png";
import "./Navbar.css"

const pages = [
  { name: 'About Us', link: '/about-us' },
  { name: 'Contact Us', link: '/contact-us' }
];

const servicePages = [
  { name: 'Red Teaming', link: '/red-team-service' },
  { name: 'Cyber Security Awareness', link: '/phishing-service' },
  { name: 'VAPT', link: '/vapt-service' },
  { name: 'Infrastructure Monitoring', link: '/infra-monitoring-service' },
  { name: 'Security Operation Centre', link: '/soc-service' }
];

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElServices, setAnchorElServices] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenServicesMenu = (event) => {
    setAnchorElServices(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseServicesMenu = () => {
    setAnchorElServices(null);
  };

  return (
    <AppBar sx={{ backgroundColor: '#000' }} position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              flexGrow: 1,
              display: 'flex',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={ionLogo} alt="company logo" className="nav-company-logo"/>
          </Typography>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="open drawer"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                '.MuiPaper-root': { bgcolor: '#d61525', color: 'white' }
              }}
            >
              <MenuItem onClick={handleCloseNavMenu} component="a" href="/about-us">About Us</MenuItem>
              <MenuItem onClick={handleCloseNavMenu} component="a" href="/contact-us">Contact Us</MenuItem>
              {servicePages.map((servicePage) => (
                <MenuItem
                  key={servicePage.name}
                  onClick={handleCloseNavMenu}
                  component="a"
                  href={servicePage.link}
                >
                  {servicePage.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex', justifyContent: 'flex-end' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
                href={page.link}
              >
                {page.name}
              </Button>
            ))}
            <Button
              key="Services"
              onMouseEnter={handleOpenServicesMenu}
              onClick={handleOpenServicesMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Services
            </Button>
            <Menu
              id="services-menu-appbar"
              anchorEl={anchorElServices}
              open={Boolean(anchorElServices)}
              onClose={handleCloseServicesMenu}
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}
              sx={{ '.MuiPaper-root': { bgcolor: '#d61525', color: 'white' } }}
            >
              {servicePages.map((servicePage) => (
                <MenuItem
                  key={servicePage.name}
                  onClick={handleCloseServicesMenu}
                  component="a"
                  href={servicePage.link}
                >
                  {servicePage.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
