import React from "react";
import redTeamVisual from "../../assets/redTeamVisual.png";
import redTeamVisualMob from "../../assets/redTeamVisualMob.png";
import './Features.css';

const Features = () => {
  return (
    <div className="features-rt-container">
      <div className="features-rt-heading">
    With Our Red Teaming Exercise, Expose Hidden Weaknesses Before Attackers Do!

      </div>
      <div className="features-rt-content">
        <img src={redTeamVisual} alt="red team explained" className="features-rt-image"></img>
        <img src={redTeamVisualMob} alt="red team explained" className="features-rt-image-mob"></img>
      </div>
    </div>
  )
}

export default Features;