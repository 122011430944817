import React from "react";
import phishingVisual from "../../assets/phishingVisual.png";
import phishingVisualMob from "../../assets/phishingVisualMob.png";
import './Features.css';

const Features = () => {
  return (
    <div className="features-phishing-container">
      <div className="features-phishing-heading">
      Fortify Your Workforce Against Deceptive Attacks
      </div>
      <div className="features-phishing-content">
        <img src={phishingVisual} alt="phishing explained" className="features-phishing-image"></img>
        <img src={phishingVisualMob} alt="phishing explained" className="features-phishing-image-mob"></img>
      </div>
    </div>
  )
}

export default Features;