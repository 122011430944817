import React from "react";
import careersVisual from "../../assets/careerVisual.png"
import './Careers.css';

const Careers = () => {
  return (
    <div className="about-careers-container">
        <div className="about-careers-content">
        <div className="about-careers-heading">JOIN OUR TEAM:
        </div>
        <div className="about-careers-main-heading">
      " At Cyberion, We're not just protecting systems; We're securing the future. Let your passion fuel our mission. "
      </div> 
        <div className="about-careers-description">
        At Cyberion, we're always on the lookout for talented individuals who are passionate about cybersecurity and eager to make a difference.  
    Whether you’re a seasoned expert or a fresh talent with a unique perspective, we welcome you to explore opportunities with us.</div> 
        </div>
        <div className="about-careers-right">
          <img src={careersVisual} alt="career"  className="about-careers-image " />
          <div className="about-careers-description3">Reach out to us at <b> <a href="mailto:careers@cyberion.com" className="career-email">careers@cyberion.com</a></b> to discuss how your skills can help us continue to secure the digital landscape. </div>
        </div>
    </div>
  )
}

export default Careers;