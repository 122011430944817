import React from "react";
import socBriefVisual from "../../assets/socBriefBg.png"
import './Brief.css';

const Brief = () => {
  return (
    <div className="brief-soc-container">
        <div className="brief-soc-content">
        <div className="brief-soc-heading">
        With Cyberion’s SOC,  
      <br></br>You’re Always A  
      <br></br>Step Ahead Of
      <br></br>Cyber Criminals.
      </div> 
      <hr className="brief-soc-hr"/>
      <div className="brief-soc-description">
      Cyberion’s SOC: The Gold Standard in Cybersecurity
Our SOC is more than a watchtower; it’s a command center of continuous protection. At Cyberion, we ensure round-the-clock threat detection, swift response, and ongoing management, keeping your organization secure from evolving threats.
      </div>
        </div>
        <img src={socBriefVisual} alt="soc explained" className="brief-soc-image"></img>
    </div>
  )
}

export default Brief;