import React from "react"
import Navbar from "../components/Navbar/Navbar";
import Landing from "../components/SOC/Landing";
import Features from "../components/SOC/Features";
import Brief from "../components/SOC/Brief";
import Banner from "../components/SOC/Banner";
import Stats from "../components/SOC/Stats";
import Footer from "../components/Footer/Footer";


const SOC = () => {

    return(
        <>
        <Navbar />
        <Landing />
        <Stats/>
        <Features />
        <Brief />
        <Banner />
        <Footer />
        </>
    )
}
export default SOC;