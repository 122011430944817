import React from "react";
import "./Banner.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Banner = () => {

    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = () => {
     navigate("/contact-us");
   };

    return(
        <div className="banner-infra-container">
            <div className="banner-infra-area">
                <div className="banner-infra-heading">
                Stay Ahead Of Potential Threats With 
                <br></br>
                Real-Time Monitoring.
                </div>
                <hr className="infra-hr"/>
                <div className="banner-infra-content">Keep your infrastructure secure with our proactive monitoring and rapid response services. Our experts constantly watch over your network, detecting anomalies and acting before threats escalate. Stay in control, protect your critical systems, and maintain peace of mind!
                </div>
                <Button variant="contained" className="banner-infra-Btn" onClick={handleRedirect}>Begin Your Monitoring Journey With Us Now</Button>
            </div>
        </div>
    )

}
export default Banner;