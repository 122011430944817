import React from "react";
import "./Banner.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Banner = () => {

    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = () => {
     navigate("/contact-us");
   };

    return(
        <div className="banner-vapt-container">
            <div className="banner-vapt-area">
                <div className="banner-vapt-heading">
                Uncover Hidden Risks Before They Become Threats.
                </div>
                <hr className="vapt-hr"/>
                <div className="banner-vapt-content">Our VAPT services meticulously assess your system for vulnerabilities, giving you the insight needed to fortify your defenses. From automated scans to manual penetration testing, we help you identify and resolve weaknesses before malicious actors can exploit them!</div>
                <Button variant="contained" className="banner-vapt-Btn" onClick={handleRedirect}>Secure Your Network Today</Button>
            </div>
        </div>
    )

}
export default Banner;