import React from "react"
import Navbar from "../components/Navbar/Navbar";
import Landing from "../components/AboutUs/Landing";
import OurMission from "../components/AboutUs/OurMission";
import Careers from "../components/AboutUs/Careers";
import Footer from "../components/Footer/Footer";

const AboutUs = () => {

    return(
        <>
        <Navbar />
        <Landing />
        <OurMission />
        <Careers />
        <Footer/>
        </>
    )
}
export default AboutUs;